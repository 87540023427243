import React, { useEffect, useState, Component } from 'react';
import './product-report.styles.scss';
import { Form, Table, Spinner } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const ProductReport = ({ warehouseProducts }) => {
    const [searchProduct, setSearchProduct] = useState("");

    const filteredProducts = warehouseProducts.filter(item => {
        return item.name.toLowerCase().includes(searchProduct.toLowerCase());
    })

    return (
        <div className='product-report-main-container'>
            <div className="glossary-view">
                <span className="box-view">
                    <h6>Total Product Count</h6>
                    <h1>{warehouseProducts.length}</h1>
                </span>
                <span className="box-view">
                    <h6>Total Purchase Cost</h6>
                    <h2>{warehouseProducts.reduce((accumalatedQuantity, product) => accumalatedQuantity + Number(product.purchasePrice), 0).toFixed(2)} INR</h2>
                </span>
            </div>
            <div className='search-view'>
                <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label><h4>Search For Product</h4></Form.Label>
                    <Form.Control type="search" placeholder="Search Product" onChange={(event) => setSearchProduct(event.target.value)} />
                </Form.Group>
            </div>
            <div className='table-view'>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="product-avail-report"
                    sheet="tablexls"
                    buttonText="Export to XLS" />
                <Table striped bordered hover size="sm" id="table-to-xls" responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Product ID</th>
                            <th>Name</th>
                            <th>HSN</th>
                            <th>Category</th>
                            <th>In Stock</th>
                            <th>GST %</th>
                        </tr>
                    </thead>
                    {warehouseProducts.length > 0 ?
                        <tbody>
                            {filteredProducts.map((item, i) =>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.itemId}</td>
                                    <td>{item.name}</td>
                                    <td>{item.itemHsn !== null && item.itemHsn.length > 0 ? item.itemHsn : "None"}</td>
                                    <td>{item.itemCategory}</td>
                                    <td>{item.availableQuantity !== null ? item.availableQuantity : 0} {item.itemMeasure}</td>
                                    <td>{item.itemGst}</td>

                                </tr>
                            )}
                        </tbody> : <Spinner style={{ marginTop: "10px" }} animation="border" />}
                </Table>
            </div>
        </div>
    )
}

export default ProductReport;