import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';

// Page imports
import MainPage from './pages/main-page.component';

const App = () => {
  return (
    <div>
      <Route exact path='/' component={MainPage} />
    </div>
  );
}

export default App;
