import React, { useEffect, useState } from 'react';
import './main-header.styles.scss';
import { Navbar, Nav, NavDropdown, Button, Form } from 'react-bootstrap';


const MainHeader = ({ setIsLoggedIn, setCurrentComponent }) => {

    // handle logout
    const handleLogout = () => {
        localStorage.clear();
        return setIsLoggedIn(false);
    }

    return (
        <div className='wh-admin-header-container'>
            <Navbar className='wh-admin-navbar-container' collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand href="/admin"><img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' height='50px' alt='wh-admin-logo'></img> | PURCHASE ADMIN</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {/* <Nav.Link href="#action/1" onClick={() => { setCurrentComponent('manageProducts'); localStorage.setItem('currentAdminComponentLocal', 'manageProducts') }}>Manage Products</Nav.Link> */}
                        <Nav.Link href="#list-warehouse-items" onClick={() => { setCurrentComponent('listWarehouseItems'); localStorage.setItem('currentAdminComponentLocal', 'listWarehouseItems') }}> Product Report</Nav.Link>
                    </Nav>
                    <Form inline>
                        <Button variant="outline-danger" onClick={() => handleLogout()}>Logout</Button>
                    </Form>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default MainHeader;