import React, { useEffect, useState } from 'react';
import './main-login.styles.scss';
import { Button, Form, Modal } from 'react-bootstrap';
import { firestore } from '../../firebase/firebase.utils';


const MainLogin = ({ setIsLoggedIn }) => {
    const [emailId, setEmailId] = useState('');
    const [password1, setPassword1] = useState('');

    // HANDLE LOGIN
    const handleLogin = async () => {
        const docRefUser = await firestore.collection("stores").doc("warehouse-catkodagu-profile");
        await docRefUser.get()
            .then((doc) => {
                if (emailId === doc.data().purchaseUn && password1 === doc.data().purchasePw) {
                    localStorage.setItem("purchaseLoggedIn", true);
                    return setIsLoggedIn(true);
                } else {
                    alert("Wrong Credentials")
                }
            })
    }

    return (
        <div className='ckw-admin-login-main-container'>
            <img src='https://catkodagu.com/static/media/cat-store-main-logo.bf2b649c.png' width='200px' alt='ckw-admin-logo' />
            <Form className='admin-login-form'>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Admin Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Admin Username" onChange={(event) => setEmailId(event.target.value)} />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Admin Password</Form.Label>
                    <Form.Control type="password" placeholder="Admin Password" onChange={(event) => setPassword1(event.target.value)} />
                </Form.Group>
                <Button variant={'primary'} onClick={() => handleLogin()}>Login</Button><br />
                {/* <a className='login-form-forgot-password' onClick={() => handleReset()}>Forgot Password?</a> */}
            </Form>
        </div>
    )
}

export default MainLogin;