import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyD2tXAVWy-rmIXxzgqWUzOEQwFFdqgbLTw",
    authDomain: "cat-store-test.firebaseapp.com",
    databaseURL: "https://cat-store-test.firebaseio.com",
    projectId: "cat-store-test",
    storageBucket: "cat-store-test.appspot.com",
    messagingSenderId: "155935973609",
    appId: "1:155935973609:web:bf4d46d03d7d489010d078"
};

firebase.initializeApp(config);

// firebase.firestore().settings({
//     cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
// });

// firebase.firestore().enablePersistence()

export const firestore = firebase.firestore();
export const imgStorage = firebase.storage();