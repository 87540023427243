import React, { useEffect, useState } from 'react';
import './main-component-view.styles.scss';
import { firestore } from '../../firebase/firebase.utils';

// Comp Imports
import ProductReport from '../product-report/product-report.component';


const MainComponentView = ({ currentComponent }) => {
    const [categoryMeta, setcategoryMeta] = useState([]);
    const [warehouseProducts, setWarehouseProducts] = useState([]);
    const [storeDetails, setStoreDetails] = useState([]);

    // Effect to fetch all warehouse product data
    useEffect(() => {
        const fetchWarehouseData = async () => {
            const docRef = await firestore.collection("warehouse-products");
            docRef.get().then((snapshot) => {
                let whData = [];
                snapshot.docs.map(doc =>
                    whData.push(doc.data()))
                setWarehouseProducts(whData)
            })
        };
        fetchWarehouseData();
    }, [])


    // Effect to fetch category and sub category meta data
    useEffect(() => {
        const fetchCategoryData = async () => {
            const categoryRef = await firestore.collection("stores").doc("coorg-basket").collection("category-meta-data");
            categoryRef.get().then((snapshot) => {
                let catData = [];
                snapshot.docs.map(async doc => { await catData.push(doc.data()); await setcategoryMeta(catData) })
            });
        };
        fetchCategoryData();
    }, [])

    // Fetch Store Details
    useEffect(() => {
        const fetchStoreDetails = async () => {
            const docRef = await firestore.collection("store-details");
            docRef.get().then((snapshot) => {
                let details = []
                snapshot.docs.map(doc =>
                    details.push(doc.data()))
                setStoreDetails(details)
            })
        };
        fetchStoreDetails();
    }, [setStoreDetails])

    return (
        <div className='wh-component-view-container'>
            {currentComponent === 'listWarehouseItems' ? <ProductReport warehouseProducts={warehouseProducts} /> : null}
        </div>
    )
}

export default MainComponentView;