import React, { useEffect, useState } from 'react';
import './main-page.styles.scss'

// Component imports
import MainHeader from '../components/main-header/main-header.component';
import MainFooter from '../components/main-footer/main-footer.component';
import MainLogin from '../components/main-login/main-login.component';
import MainComponentView from '../components/main-component-view/main-component-view.component';

const MainPage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('purchaseLoggedIn')));
    const [currentComponent, setCurrentComponent] = useState('');

    // Handle Local for logged in
    useEffect(() => {
        const onLoadAdminChanges = async () => {
            await setIsLoggedIn(Boolean(localStorage.getItem('purchaseLoggedIn')));
        };
        onLoadAdminChanges();
    }, [])

    // Effect store view in local storage
    useEffect(() => {
        const getLocatStorage = () => {
            localStorage.getItem('currentAdminComponentLocal') === null ? setCurrentComponent(localStorage.setItem('currentAdminComponentLocal', 'listWarehouseItems'))
                :
                setCurrentComponent(localStorage.getItem('currentAdminComponentLocal'));
        };
        getLocatStorage();
    }, []);


    return (
        <div className='main-page-components'>
            <div className='salar-admin-page-main-container'>
                {isLoggedIn === false ?
                    <MainLogin setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)} />

                    :
                    <>
                        <MainHeader
                            setIsLoggedIn={(isLoggedIn) => setIsLoggedIn(isLoggedIn)}
                            setCurrentComponent={(currentComponent) => setCurrentComponent(currentComponent)} />
                        <MainComponentView currentComponent={currentComponent} />
                        <MainFooter />
                    </>
                }
            </div>
        </div>
    )
}

export default MainPage;
